import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish max:`}</p>
    <p>{`1-Power Clean + 1-Hang Power Clean + 1-Hang Squat Clean`}</p>
    <p>{`then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`Hang Power Clean (115/75)(RX+ 135/95)`}</p>
    <p>{`&`}</p>
    <p>{`Box Jump Over (24/20″)`}</p>
    <p><em parentName="p">{`*`}{`1 of each minute 1:00, 2 of each minute 2:00, 3 of each minute 3:00,
etc. until failure.`}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`compare score to 7/24/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      